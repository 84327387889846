import { Header } from './Header'

import { useGetDemand } from '@/hooks/useGetDemand'
import { twMerge } from '../../styles/tailwindMergeConfig'

import { Skeleton } from './Skeleton'
import { TabsLeft } from './TabsLeft'
import { TabsRight } from './TabsRight'

export function DemandsDetails() {
  const { data, isLoading, isError } = useGetDemand()

  if (isLoading || isError) {
    return <Skeleton isLoading={isLoading} />
  }

  if (data) {
    return (
      <main className="flex size-full flex-col items-center overflow-auto bg-cromai-m3-sys-light-background px-7 pb-10 pt-6 font-mono dark:bg-cromai-m3-sys-dark-background lg:h-screen">
        <Header />
        <section
          className={twMerge(
            'lg:flex-row',
            'mt-6 flex h-[calc(100vh-11.1rem)] w-full max-w-screen-xl flex-col gap-6 '
          )}
        >
          <TabsLeft />
          <TabsRight />
        </section>
      </main>
    )
  }
}
