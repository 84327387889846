import { useState } from 'react'

import * as RadixSelect from '@radix-ui/react-select'
import { Select } from '@/components'

import { useGetSummaryBoards } from '@/hooks/boards/useGetSummaryBoards'
import { twMerge } from '@/styles/tailwindMergeConfig'

import { Columns } from './Columns'
import { Tags } from './Tags'
import { Histories } from './Histories'

export function BoardSettings() {
  const [boardSelected, setBoardSelected] = useState('')

  const { data: summaryBoards } = useGetSummaryBoards()

  const summaryBoard = summaryBoards?.find(
    (board) => board.name === boardSelected
  )

  return (
    <main className="flex h-[calc(100vh-3.5rem)] w-full flex-col items-center overflow-auto px-8">
      <div className="mt-4 flex w-full max-w-screen-xl flex-col items-start justify-between">
        <label className=" flex flex-col gap-2 font-mono text-labelMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Quadro
          <Select
            value={boardSelected}
            onValueChange={setBoardSelected}
            placeholder="Selecione..."
          >
            {summaryBoards?.map((board) => (
              <RadixSelect.Item
                className={twMerge(
                  'cursor-pointer px-3 py-1.5 outline-none',
                  'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover'
                )}
                value={board.name}
                key={board.id}
              >
                <RadixSelect.ItemText>{board.name}</RadixSelect.ItemText>
              </RadixSelect.Item>
            ))}
          </Select>
        </label>

        <section className="flex w-full gap-12">
          <Columns summaryBoard={summaryBoard} />
          <Tags summaryBoard={summaryBoard} />
        </section>
        <section className="mb-4 flex w-full gap-12">
          <Histories boardId={summaryBoard?.id} />
        </section>
      </div>
    </main>
  )
}
