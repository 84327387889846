import { Column as ColumnTypes } from '@/types/Board'
import { Card } from './Card'

import { ContextMenuCard } from './ContextMenuCard'
import { twMerge } from '@/styles/tailwindMergeConfig'

type ColumnProps = {
  totalArea: number
  percentage: string
  columnIndex: number
} & ColumnTypes

export function Column({
  id,
  name,
  cards,
  isMovableCards,
  percentage,
  totalArea,
  columnIndex
}: ColumnProps) {
  return (
    <div className="flex flex-col gap-4">
      <h1 className="font-mono text-labelLarge text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background">
        {name}
      </h1>
      <section
        className={twMerge(
          'flex justify-between pr-3',
          'font-mono text-labelLarge',
          'text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background'
        )}
      >
        <span className="font-mono   text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background">
          # {cards.length}
        </span>
        <div className="flex gap-4">
          <span>{totalArea} ha</span>
          {name !== 'Concluída' && <span>({percentage}%)</span>}
        </div>
      </section>
      <div className="h-[calc(100vh-220px)]  overflow-auto">
        <section className="flex h-full w-52 flex-col gap-2">
          {cards?.map((card, index) => {
            return (
              <ContextMenuCard
                key={card.demandId}
                demandId={card.demandId}
                cardIndex={index}
                columnIndex={columnIndex}
                currentColumnId={id}
                currentTags={card.tags}
                demandName={card.demandName}
                isMovableCards={isMovableCards}
              >
                <Card {...card} />
              </ContextMenuCard>
            )
          })}
        </section>
      </div>
    </div>
  )
}
